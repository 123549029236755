<template>
    <div class="invite-box">
        <img src="@/assets/index/invite/icon_back.svg" class="back-btn" @click="backIndex">
        <div class="title-box">
            <img src="@/assets/index/invite/title.png">
            <div>
                {{invitePageInfo.start_time | changDayType}} - {{invitePageInfo.end_time | changDayType}}
            </div>
        </div>
        <div class="explain-box">
            <!-- 三步得好礼 -->
            <div class="explain-item first-explain">
                <img src="@/assets/index/invite/explain_title_one.svg" class="title-back">
                <div class="step-box">
                    <div class="step-item-box" v-for="(item,index) in inviteStepList" :key="index">
                        <div class="step-item">
                            <div class="step-item-icon">
                                <img :src="item.icon">
                            </div>
                            <div class="step-title">
                                {{item.stepTitle}}
                            </div>
                            <div class="step-explain">
                                {{item.explain}}
                            </div>
                        </div>
                        <img src="@/assets/index/invite/step_arrow.svg" class="step-arrow" v-if="index!== inviteStepList.length-1">
                    </div>
                </div>
                <div class="invite-btn-box">
                    <div class="invite-btn" @click="goInviteImagePage">
                        立即邀请好友
                    </div>
                </div>
            </div>
            <!-- 邀请码 -->
            <div class="invite-code-box">
                <div class="invite-code">
                    我的邀请码：
                    <div class="code-box">
                        {{invitePageInfo.invite_code}}
                    </div>
                    <div class="copy-code-btn" @click="copyInviteCode">
                        复制邀请码
                    </div>
                </div>
            </div>
            <!-- 邀请记录 -->
            <div class="explain-item invite-history-box">
                <img src="@/assets/index/invite/explain_title_tow.svg" class="title-back">
                <div class="history-list" :class="inviteHistoryList.length<6?'history-list-hidden':''" v-if="inviteHistoryList.length>0">
                    <div class="history-item" v-for="(item, index) in inviteHistoryList" :key="index">
                        <div class="history-item-avater-box">
                            <img class="history-item-avater" src="@/assets/index/invite/head.svg" alt="">
                        </div>
                        <div class="history-item-name">
                            {{item.nickname}}
                        </div>
                    </div>
                    
                    <div class="history-item" v-for="(item, index) in inviteHistoryBlankList" :key="index">
                        <div class="history-item-avater-box">
                            <img class="history-item-avater" src="@/assets/index/invite/head.svg" alt="">
                        </div>
                        <div class="history-item-name history-item-name-active">
                            待邀请
                        </div>
                    </div>
                </div>
                <div class="history-list-blank" v-else>
                    <img src="@/assets/index/invite/blank_invite.svg" >
                    <div>
                        暂无奖励，快去邀请好友吧~
                    </div>
                </div>
                <div class="line-box" v-if="inviteHistoryList.length>0">
                    <div class="line"></div>
                </div>
                <div class="reward-box" v-if="inviteHistoryList.length>0">
                    累计获得奖励：<span class="reward-item">￥</span><span class="reward-money">{{invitePageInfo.money|changMoney}}</span>
                </div>
            </div>
            <!-- 活动规则 -->
            <div class="explain-item active-rules" v-if="invitePageInfo.rule_text && invitePageInfo.rule_text.length>0">
                <img src="@/assets/index/invite/explain_title_three.svg" class="title-back">
                <div class="rule-box">
                    {{invitePageInfo.rule_text}}
                </div>
            </div>
        </div>
        
        
    </div>
</template>

<script>
    export default {
        components: {

        },
        props: {
            invitePageInfo:Object,
            inviteStepList:Array,
            inviteHistoryList:Array,
            inviteHistoryBlankList:Array
        },
        data() {
            return {

            }
        },
        methods: {
            copyInviteCode() {
                this.$emit('copyInviteCode')  
            },
            goInviteImagePage() {
                this.$emit('goInviteImagePage')  
            },
            backIndex() {
                this.$emit('backIndex')
            }
        }
    }
</script>

<style scoped>
    .invite-box {
        background-image: url('../../../../assets/index/invite/back.png');
        background-size: 100% auto;
        background-repeat: no-repeat;
        width: 100%;
        padding: 20px 17px;
        background-color: #FECAB2;
    }

    .back-btn {
        width: 21px;
    }

    .title-box {
        padding:0 10px;
        margin-top: -10px;
    }
    .title-box>img {
        width: 100%;
    }
    .title-box>div {
        margin-top: -10px;
        width: 100%;
        text-align: center;
        font-size: 15px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #FFFFFF;
    }
    .explain-box {
        padding-top: 278px;
    }
    .explain-item {
        border: 1px solid #FF724D;
        border-radius: 12px;
        background-color: #FFFFFF;
        position: relative;
    }
    .title-back {
        position: absolute;
        top: 1px;
        left: 50%;
        transform: translate(-50%, -50%);
    }
    .first-explain {
        padding: 47px 32px 20px 32px;
    }
    .step-box {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .step-item-box {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .step-item {
        height: 82px;
        display: flex;
        align-items: center;
        flex-direction: column;
    }
    .step-item-icon {
        padding-bottom: 11px;
    }
    .step-item-icon>img {
        width: 34px;
    }
    .step-title {
        text-align: center;
        padding-bottom: 3px;
        font-size: 11px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #333333;
    }
    .step-explain {
        text-align: center;
         font-size: 10px;
         font-family: PingFang SC-Regular, PingFang SC;
         font-weight: 400;
         color: #666666;
    }
    .step-arrow {
        width: 38px;
    }
    .invite-btn-box {
        padding-top: 16px;
    }
    .invite-btn {
        width: 100%;
        height: 41px;
        background: linear-gradient(0deg, #FF5236 0%, #F7181A 53%, #FF9DA2 100%);
        box-shadow: 0px 1px 3px 1px #EB6965;
        border-radius: 33px 33px 33px 33px;
        border: 2px solid #FFF8C4;
        font-size: 15px;
        font-family: PingFang SC-Medium, PingFang SC;
        font-weight: 500;
        color: #FFFFFF;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .invite-code-box {
        padding: 15px 0 28px 0;
    }
    .invite-code {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 18px;
        width: 100%;
        height: 60px;
        background: #FEF1EB;
        border-radius: 10px 10px 10px 10px;
        border: 1px solid #F3C5B1;
        font-size: 14px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #AA5B25;
    }
    .code-box {
        flex: 1;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        padding-right: 27px;
    }
    .copy-code-btn {
        width: 82px;
        height: 28px;
        background: linear-gradient(90deg, #FD7579 0%, #F92621 100%);
        border-radius: 17px 17px 17px 17px;
        font-size: 11px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #FFFFFF;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .invite-history-box {
        padding: 53px 0 15px 32px;
    }
    .history-list {
        display: flex;
        align-items: center;
        flex-wrap: nowrap;
        width: 100%;
        overflow-x: scroll;
    }
    .history-list-hidden {
        overflow-x: hidden;
    }
    .history-item {
        padding-right: 22px;
        display: flex;
        align-items: center;
        flex-direction: column;
    }
    .history-item-avater-box {
        height: 42px;
        width: 42px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;
    }
    .history-item-avater {
        width: 100%;
    }
    .history-item-name {
        font-size: 12px;
        font-family: PingFang SC-Medium, PingFang SC;
        font-weight: 500;
        color: #333333;
        padding-top: 12px;
    }
    .history-item-name-active {
        
        color: #666666;
    }
    .history-list-blank {
        padding-top: 22px;
        display: flex;
        align-items: center;
        flex-direction: column;
        padding-right: 32px;
    }
    .history-list-blank>img {
        width: 56px;
    }
    .history-list-blank>div {
        padding-top: 18px;
        padding-bottom: 50px;
        text-align: center;
        font-size: 14px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #666666;
        line-height: 0px;
    }
    .line-box {
        padding-right: 21px;
        padding-top: 21px;
    }
    .line {
        width: 100%;
        border-bottom: 1px solid #FFCEC1; ;
    }
    .reward-box {
        padding-top: 17px;
        font-size: 14px;
        font-family: PingFang SC-Medium, PingFang SC;
        font-weight: 500;
        color: #333333;
    }
    .reward-item {
        font-size: 8px;
    }
    .reward-money {
        font-size: 20px;
    }
    .active-rules {
        padding: 34px 25px 25px 25px;
        margin-top: 37px;
    }
    .active-rules-text {
        padding-top: 16px;
        font-size: 13px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #333333;
    }
    .rule-box {
        white-space:pre-wrap;
    }
</style>
<template>
    <div style="padding: 0; overflow-y: scroll;">
        <Horizontal ref="Horizontal"
        :inviteStepList="inviteStepList"
        :inviteHistoryList="inviteHistoryList"
        :inviteHistoryBlankList="inviteHistoryBlankList"
        :invitePageInfo="invitePageInfo"
        @backIndex="backIndex"
        @copyInviteCode="copyInviteCode"
        @goInviteImagePage="goInviteImagePage"
         v-if="$store.getters.currentPageVersion === 'Horizontal'"></Horizontal>

        <Vertical ref="Vertical"
        :inviteStepList="inviteStepList"
        :inviteHistoryList="inviteHistoryList"
        :inviteHistoryBlankList="inviteHistoryBlankList"
        :invitePageInfo="invitePageInfo"
        @backIndex="backIndex"
        @copyInviteCode="copyInviteCode"
        @goInviteImagePage="goInviteImagePage"
        v-else></Vertical>

    </div>
</template>

<script>
    import Horizontal from '@/components/Index/InviteIndex/Horizontal/Index.vue'
    import Vertical from '@/components/Index/InviteIndex/Vertical/Index.vue'
    
    //三步得好礼
    import stepClick from '@/assets/index/invite/step_click.svg'
    import stepOpen from '@/assets/index/invite/step_open.svg'
    import stepLogin from '@/assets/index/invite/step_login.svg'
    
    
    export default {
        components: {
            Horizontal,
            Vertical
        },
        data() {
            return {
                inviteStepList:[
                    {
                        stepTitle:'点击按钮',
                        explain:'分享给好友',
                        icon:stepClick
                    },
                    {
                        stepTitle:'好友打开',
                        explain:'链接安装下载',
                        icon:stepOpen
                    },
                    {
                        stepTitle:'好友登录',
                        explain:'填写邀请码',
                        icon:stepLogin
                    }
                ],
                inviteHistoryList:[
                    {},
                    {},
                    // {},
                    // {},
                    // {},
                    // {},
                    // {},
                    // {},
                    // {},
                    // {},
                    // {},
                ],
                inviteHistoryBlankList:[],
                invitePageInfo:{
                    money:'0.00',
                    rule_text:'',
                    start_time:'',
                    end_time:'',
                    invite_code:'',
                    promotional_img:'',
                    invite_url:''
                }
            }
        },
        created() {
            this.getInvitePageInfo()
            console.log(this.inviteHistoryList.length<5)
            
        },
        methods: {
            //获取邀请信息
            getInvitePageInfo() {
                let requestData = {
                    role_id: this.$store.state.roleId,
                    token: this.$store.state.token,
                    screen_direction: this.$store.getters.currentPageVersion&&this.$store.getters.currentPageVersion==='Horizontal'?2:1
                }
                this.$api.redPacket.getInvitePageInfo(requestData).then(res => {
                    if (res.data.code === 200) {
                        this.invitePageInfo = res.data.data
                        this.inviteHistoryList = res.data.data.list
                        if(this.inviteHistoryList.length<5) {
                          const blankCount =  5-this.inviteHistoryList.length
                          for(let i=0; i<blankCount;i++) {
                              this.inviteHistoryBlankList.push({dd:'dd'})
                          }
                          console.log(this.inviteHistoryBlankList)
                        }
                    }
                })
            },
            //复制邀请码
            copyInviteCode() {
                this.$common.copyText(this.invitePageInfo.invite_code, '邀请码')
            },
            //跳转到邀请图片页面
            goInviteImagePage() {
                this.$router.push({
                    name:'InviteImage',
                    params:{
                        imageInfo: {
                            imageUrl:this.invitePageInfo.promotional_img,
                            codeUrl:this.invitePageInfo.invite_url,
                            inviteCode:this.invitePageInfo.invite_code
                        }
                    }
                })
            },
            //返回首页
            backIndex() {
                this.$router.replace({
                    name:'IndexRedPacket',
                    params:{
                        pagePosition:'otherTask'
                    }
                })
            }
        }
    }
</script>

<style>
</style>